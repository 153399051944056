import { ReactElement, useEffect, useMemo, useState, useReducer, useCallback, useRef } from 'react'
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'
import style from './index.module.less'
import { useDispatch, useSelector } from 'react-redux'
import { setToken, setUserInfo } from '@src/redux/actions/basic'
import qs from 'query-string'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { Empty, Modal, SafeArea, Toast } from 'antd-mobile'
import { getRequest, delRequest, addRequest, recordRequest, getLicenseUrlRequest } from './ajax'
import { awaitWrap, formatDate } from '@src/assets/js/tool'
import classNames from 'classnames'
import { DeleteOutline, CloseCircleOutline } from 'antd-mobile-icons'
import { CourseDetailSchema } from '@src/components/schema/courseSchema'

let timer: NodeJS.Timeout
const oMap = { beginTime: 0, currentTime: 0, uid: '', token: '' }

interface Player {
  src: (v: string) => void
  on: (t: string, l: () => void) => void
  off: (t: string, l: () => void) => void
  width: (v: number) => void
  height: (v: number) => void
  currentTime: (v?: number) => number
  ready: (v: () => void) => void
  dispose: () => void
  loadVideoByID: (v: Object) => void
}

async function initLicenseUrl (setLicenseUrl: (v: string) => void): Promise<void> {
  Toast.show({ content: '加载中', icon: 'loading', duration: 120 * 1000 })
  const [e, d] = await awaitWrap(getLicenseUrlRequest())
  if (e === null) {
    Toast.clear()
    if (typeof d === 'string') {
      setLicenseUrl(d)
    }
  }
}

async function init (id: string, setData: (v: CourseDetailSchema) => void): Promise<void> {
  const h = Toast.show({ content: '加载中', icon: 'loading', duration: 120 * 1000 })
  const [e, d] = await awaitWrap(getRequest(oMap.uid, oMap.token, id))
  h.close()
  if (e === null && d !== null) {
    setData(d)
  }
}

async function delHandle (id: string, callback: () => void): Promise<void> {
  const h = Toast.show({ content: '删除中', icon: 'loading', duration: 120 * 1000 })
  const [e] = await awaitWrap(delRequest(oMap.uid, oMap.token, id))
  h.close()
  if (e === null) {
    Toast.show({ content: '删除成功', icon: 'success' })
    callback()
  }
}

async function addHandle (id: string, content: string, callback: () => void): Promise<void> {
  const h = Toast.show({ content: '提交中', icon: 'loading', duration: 120 * 1000 })
  const [e] = await awaitWrap(addRequest(oMap.uid, oMap.token, id, content))
  h.close()
  if (e === null) {
    setTimeout(() => {
      Toast.show({ content: '提交成功', icon: 'success', duration: 1000 })
    }, 200)
    callback()
  }
}

async function recordTime (mediaid: string): Promise<void> {
  const t = oMap.currentTime - oMap.beginTime
  if (t === 0) {
    return undefined
  }
  const [e] = await awaitWrap(recordRequest(oMap.uid, oMap.token, mediaid, oMap.beginTime, t))
  if (e !== null) {
    console.error(e)
  }
}

async function recordOneTime (mediaid: string, time: number): Promise<void> {
  const [e] = await awaitWrap(recordRequest(oMap.uid, oMap.token, mediaid, time, 1))
  if (e !== null) {
    console.error(e)
  }
}

function reducer (state: { time: number }, action: { type: string }): { time: number } {
  if (action.type === 'add') {
    return {
      time: state.time + 1
    }
  }
  if (action.type === 'clear') {
    return {
      time: 0
    }
  }
  throw Error('Unknown action.')
}

function Main (): ReactElement {
  const dispatch = useDispatch()
  const videoRef = useRef(null)
  const [version, setVersion] = useState(Date.now())
  const [courseid, setCourseid] = useState('')
  const [mediaid, setMediaid] = useState('')
  const [player, setPlayer] = useState<Player | null>(null)
  const [data, setData] = useState<CourseDetailSchema | null>(null)
  const [content, setContent] = useState('')
  const [licenseUrl, setLicenseUrl] = useState('')
  const [state, sDispatch] = useReducer(reducer, { time: 0 })
  const [ready, setReady] = useState(false)
  const userInfo = useSelector((state: { basic: BASIC_STATE }) => {
    return state.basic.userInfo
  })
  const media = useMemo(() => {
    if (mediaid !== '' && data !== null) {
      if (data.medias instanceof Array) {
        const m = data.medias.find(li => li.id === mediaid)
        if (m !== undefined) {
          return m
        }
      }
    }
    return null
  }, [mediaid, data])
  const video = useMemo(() => {
    if (media !== null && data !== null) {
      if (typeof data.app_id === 'string' && data.app_id.length > 0 && typeof media.thirdid === 'string' && media.thirdid.length > 0 && typeof media.psign === 'string' && media.psign.length > 0) {
        return {
          fileID: media.thirdid,
          appID: data.app_id,
          psign: media.psign
        }
      }
    }
  }, [media, data])
  const src = useMemo(() => {
    if (media !== null) {
      return media.media_url
    }
    return ''
  }, [media])
  const title = useMemo(() => {
    if (media !== null) {
      return media.title
    }
    return ''
  }, [media])
  const list = useMemo(() => {
    if (data !== null && data.medias instanceof Array) {
      const o = data.medias.find(li => li.id === mediaid)
      if (o !== undefined && o.comments instanceof Array) {
        return o.comments
      }
    }
    return []
  }, [data, mediaid])
  const loopTime = useCallback(() => {
    timer = setTimeout(() => {
      sDispatch({ type: 'add' })
      loopTime()
    }, 1000)
  }, [sDispatch])
  const isExpire = useMemo(() => {
    if (data !== null && typeof data.expiry_datetime === 'number') {
      return data.expiry_datetime * 1000 < Date.now()
    }
    return false
  }, [data])
  useEffect(() => {
    if ((player != null) && (media != null)) {
      player.ready(() => {
        player.currentTime(media.end_datetime)
      })
    }
  }, [player, media])
  useEffect(() => {
    const params = qs.parse(location.search)
    if (typeof params.uid === 'string' && params.uid.length > 0) {
      const u = JSON.parse(JSON.stringify(userInfo))
      u.id = params.uid
      dispatch(setUserInfo(u))
      oMap.uid = params.uid
      setReady(true)
    }
    if (typeof params.token === 'string' && params.token.length > 0) {
      dispatch(setToken(params.token))
      oMap.token = params.token
    }
    if (typeof params.ci === 'string' && params.ci.length > 0) {
      setCourseid(params.ci)
    }
    if (typeof params.mi === 'string' && params.mi.length > 0) {
      setMediaid(params.mi)
    }
  }, [])
  useEffect(() => {
    if (courseid !== '' && ready) {
      init(courseid, setData)?.then(null, null)
      initLicenseUrl(setLicenseUrl).catch(e => console.error(e))
    }
  }, [courseid, version, ready])
  useEffect(() => {
    if (data !== null && data.status === '已发布' && licenseUrl !== '' && videoRef.current !== null) {
      const o = TCPlayer('player-container-id', {
        sources: [],
        licenseUrl // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
      })
      setPlayer(o)
    }
  }, [licenseUrl, videoRef, data])
  useEffect(() => {
    return () => {
      if (player !== null) {
        player.dispose()
      }
    }
  }, [player])
  useEffect(() => {
    if (player !== null) {
      if (video !== undefined) {
        player.loadVideoByID(video)
      } else if (src !== '') {
        player.src(src)
      }
    }
  }, [src, player, video])
  useEffect(() => {
    if (player !== null) {
      const b = document.body
      const w = b.offsetWidth - 20
      player.width(w)
      player.height(w / 16 * 9)
    }
  }, [player])
  useEffect(() => {
    document.title = '课程视频'
    return () => {
      (timer !== null || timer !== undefined) && clearTimeout(timer)
    }
  }, [])
  useEffect(() => {
    function playHandle (): void {
      if (player === null) {
        return undefined
      }
      const c = player.currentTime()
      oMap.beginTime = c
      oMap.currentTime = c
      loopTime()
      recordOneTime(mediaid, c).then(null, null)
    }
    function pauseHandle (): void {
      if (player === null) {
        return undefined
      }
      const c = player.currentTime()
      clearTimeout(timer)
      if (Math.abs(oMap.currentTime - c) > 1) {
        recordTime(mediaid).then(null, null)
        oMap.beginTime = c
      }
    }
    function timeupdateHandle (): void {
      if (player === null) {
        return undefined
      }
      const c = player.currentTime()
      if (Math.abs(oMap.currentTime - c) > 1) {
        clearTimeout(timer)
        recordTime(mediaid).then(null, null)
        oMap.beginTime = c
      }
      oMap.currentTime = c
    }
    function endedHandle (): void {
      if (player === null) {
        return undefined
      }
      recordTime(mediaid).then(null, null)
      clearTimeout(timer)
      oMap.currentTime = 0
      oMap.beginTime = 0
    }
    if (player !== null) {
      player.on('play', playHandle)
      player.on('pause', pauseHandle)
      player.on('timeupdate', timeupdateHandle)
      player.on('ended', endedHandle)
      return () => {
        player.off('play', playHandle)
        player.off('pause', pauseHandle)
        player.off('timeupdate', timeupdateHandle)
        player.off('ended', endedHandle)
      }
    }
  }, [player, loopTime])
  useEffect(() => {
    if (state.time >= 60) {
      recordTime(mediaid).then(null, null)
      sDispatch({ type: 'clear' })
    }
  }, [mediaid, state])
  useEffect(() => {
    if (typeof mediaid === 'string' && mediaid.length > 0) {
      window.addEventListener('beforeunload', (event) => {
        if (oMap.currentTime - oMap.beginTime === 0) {
          recordTime(mediaid).then(null, null)
          dispatch({ type: 'clear' })
        }
      })
    }
  }, [mediaid])
  if ((data !== null && data.status !== '已发布') || isExpire) {
    return (
      <div className={style.container}>
        <div className={style.noread}>
          <CloseCircleOutline className={style['noread-icon']} />
          <span>课程不存在或已下架</span>
          <video ref={videoRef} className={style.player} id="player-container-id" style={{ display: 'none' }}></video>
        </div>
      </div>
    )
  }
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>{title}</div>
        <div className={style.video}>
          <video ref={videoRef} className={style.player} id="player-container-id" preload="auto" playsInline webkit-playsinline></video>
        </div>
        <div className={style['comment-title']}>
          <span className={style['comment-title-left']}>
            <span className={style.first}>评论</span>
            <span>{list.length}</span>
          </span>
        </div>
      </div>
      <div className={style.content}>
        <div className={style['content-inner']}>
          {list.length === 0 && <Empty description='暂无评论' />}
          {
            list.map((li, i) => {
              const isMe = li.operatorid === userInfo.id
              return (
                <div className={style['comment-item']} key={li.id}>
                  <div className={style['comment-item-info']}>
                    <div className={style['comment-item-info-left']}>
                      <span className={classNames(style['comment-item-info-name'], { [style.active]: isMe })}>
                        {isMe ? '我' : li.operator}
                      </span>
                      <span className={style['comment-item-time']}>{formatDate(li.create_datetime, 'YYYY.MM.DD')}</span>
                    </div>
                    {
                      isMe && (
                        <div className={style['comment-del']} onClick={async () => {
                          const res = await Modal.confirm({
                            content: '您确定要删除本条评论吗？'
                          })
                          if (res) {
                            delHandle(li.id, () => { setVersion(Date.now()) }).catch(e => console.error(e))
                          }
                        }}>
                          <DeleteOutline />
                        </div>
                      )
                    }
                  </div>
                  <div className={style['comment-content']}>{li.content}</div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className={style.footer}>
        <div className={style['comment-input-container']}>
          <input placeholder='请输入' value={content} className={style['comment-input']} onChange={e => { setContent(e.target.value) }} />
          <button
            className={style['comment-btn']}
            onClick={async () => {
              if (content.trim() === '') {
                Toast.show({ content: '评论内容不能为空' })
                return undefined
              }
              const res = await Modal.confirm({
                content: '您确定要提交吗？'
              })
              if (res) {
                addHandle(mediaid, content.trim(), () => { setVersion(Date.now()); setContent('') }).catch(e => console.error(e))
              }
            }}
          >确 定</button>
        </div>
        <SafeArea position='bottom' />
      </div>
    </div>
  )
}

export default Main
